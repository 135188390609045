import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation('common');
    return (
        <>
            <footer className="relative bg-gray-900 pt-14 pb-8">
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                style={{ height: "80px"}}  
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="#"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-300 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
                <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden mt-20">
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="#"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0">

                        <polygon
                            className="text-gray-300 fill-current"
                            points="2560 0 2560 100 0 100">
                        </polygon>
                    </svg>
                </div>
                <div className="container mx-auto md:px-0 px-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-1/4 px-4 sm:text-center">
                            <div className="sm:mt-6 lg:mt-0">
                                <div className="w-full lg:w-2/3 px-4">
                                    <span className=" block font-poppins uppercase text-white text-md font-semibold mb-2">
                                        {t('contactus.send_email')}
                                    </span>                                    
                                    <div className="mt-6">
                                    <a className="font-semibold font-poppins" href="mailto:monica.velez@iestalentservices.com" target="blank">
                                            <button
                                                className=" hover:bg-gray-900 font-poppins hover:text-white bg-white text-blue-900 shadow-lg font-normal h-12 w-12 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                                                type="button"
                                            >
                                                <i className="fas fa-at fa-lg"></i>
                                            </button>
                                        </a>
                                    </div>
                                    {/* <span className=" block font-poppins text-white text-sm font-normal mt-2">
                                        {t('menu.sendEmail')}
                                    </span>    */}    
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/4 px-4">
                            <div className="flex flex-wrap items-top mb-6">
                                
                                <div className="lg:w-3/12 px-4 lg:mt-0 mt-6 w-1/2">
                                    <span className="font-poppins block uppercase text-white text-md text-justify font-semibold mb-2">
                                         {t('personal.address')}
                                    </span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <p className="font-poppins font-light text-justify text-white block pb-2 text-sm" > {t('contactus.direction_line1')}</p>
                                            <p className="font-poppins font-light text-justify text-white block pb-2 text-sm" > {t('contactus.direction_line2')}</p>
                                        </li>
                                        <li>
                                            <p className="font-poppins text-white font-light block pb-2 text-sm" > {t('contactus.zip')}</p>
                                        </li>
                                        <li>
                                            <p className="font-poppins text-white font-light block pb-2 text-sm">
                                            {t('contactus.nit')}
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="lg:w-3/12 px-4 lg:mt-0 mt-6 w-1/2">
                                    <span className="font-poppins block uppercase text-white text-md font-semibold mb-2">
                                        {t('menu.contactus').toUpperCase()}
                                    </span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <p className="font-poppins text-white capitalize font-light block pb-2 text-sm">
                                                {t('contactus.company')}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="font-poppins text-white font-light block pb-2 text-sm" >{t('personal.phone')}:  {t('contactus.phone')} </p>
                                        </li>
                                        <li>
                                            <div className="whitespace-normal">
                                            <p className="font-poppins overflow-hidden overflow-ellipsis text-white font-light block pb-2 text-sm text-justify" > Email: {t('contactus.email')}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="lg:w-3/12 px-4 lg:mt-0 mt-4 w-1/2">
                                    <span className="font-poppins block uppercase text-white text-md font-semibold mb-2">{t('home.linkHelps')}</span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a className="font-poppins text-white hover:text-blue-800 font-light block pb-2 text-sm" href='../hiring/process'>{t('hiring.title')}</a>
                                        </li>
                                        <li>
                                            <a className="font-poppins text-white hover:text-blue-800  font-light block pb-2 text-sm" href='../question'>{t('hiring.faq.title')}</a>
                                        </li>
                                        <li>
                                            <a className="font-poppins text-white hover:text-blue-800  font-light block pb-2 text-sm" href='../contactus'>{t('menu.contactus')}</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="lg:w-3/12 px-4 lg:mt-0 mt-4 w-1/2">
                                    <span className="font-poppins block uppercase text-white text-md font-semibold mb-2">{t('home.other')}</span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a className="font-poppins text-white hover:text-blue-800 font-normal block pb-2 cursor-pointer text-sm" href='../privacy'>{t('home.politicPrivate')}</a>
                                        </li>
                                        <li>
                                        <a className="font-poppins text-white hover:text-blue-800 font-light block cursor-pointer pb-2 text-sm" href='../terms'>{t('home.term')} &amp; {t('home.condic')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-6 border-gray-400" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-xs font-poppins font-normal text-white py-1">
                                Copyright © by
                                <label className="text-white hover:text-red-500 cursor-pointer"> IES Talent, Colombia</label>.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import TextField from "@material-ui/core/TextField";

import { savePortfolio } from "../../redux/actions/portfolioActions";
import { handleErrorMessage } from "../../utils/functions";

import Button from "../common/Button";
import { SingleError } from "../common";

export default function FormAdd(props) {
  const dispatch = useDispatch();
  const port = useSelector((state) => state.portfolioReducers);
  let { status, closeFormAdd } = props;
  const { t } = useTranslation("common");
  const [isFileBigger, setIsFileBigger] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      file: "",
      link: "",
      description: "",
    },
  });

  const onSubmitData = (data) => {
    var formData = new FormData();
    const { file, link } = data;
    const fileValid = file?.size / 1024 / 1024 < 1;

    if (!file && link) {
      Object.entries(data).map(([key, item]) => 
        formData.append(key, item)
      );

      dispatch(savePortfolio(formData));
    }

    if (file && !fileValid) {
      setIsFileBigger(true);
      return;
    } else if (fileValid && link) {
      Object.entries(data).map(([key, item]) => 
        formData.append(key, item)
      );

      dispatch(savePortfolio(formData));
    }

    closeFormAdd();
  };

  useEffect(() => {
    reset({ file: "", link: "", description: "" });
  }, [reset, closeFormAdd]);

  useEffect(() => {
    register("file");
  }, [register]);

  return (
    <>
      {status && (
        <>
          <SingleError
            status={isFileBigger}
            styleParent="mb-5 w-1/2 mx-auto"
            errors={t("portfolio.error.cv_limit")}
            closer={() => setIsFileBigger(false)}
          />
          <form
            action=""
            className="w-full animate__animated animate__fadeIn animate__faster"
            onSubmit={handleSubmit(onSubmitData)}
          >
            <div className="w-full md:flex md:mb-5">
              <div className="py-2 md:py-0 md:w-1/2 mx-2">
                <TextField
                  type="file"
                  id="file"
                  name="file"
                  label={t("portfolio.file")}
                  onChange={(event) => setValue("file", event.target?.files[0])}
                  variant="outlined"
                  className="w-full"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: ".png,.jpg,.jpeg,.pdf,.docx,.doc,.odt,.odg",
                  }}
                  error={errors.file ? true : false}
                  helperText={t(handleErrorMessage(port, "file"))}
                />
                <ErrorMessage
                  errors={errors}
                  name="file"
                  render={({ message }) => (
                    <p className="text-sm mt-1 text-red-500">{message}</p>
                  )}
                />
              </div>
              <div className="py-2 md:py-0 md:w-1/2 mx-2">
                <Controller
                  name="link"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("portfolio.error.link_urlrequired"),
                    },
                    pattern: {
                      value: /^https?:\/\/[\w]+(\.[\w]+)+[/#?]?.*$/,
                      message: t("portfolio.error.link_url"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="link"
                      name="link"
                      label={t("portfolio.link")}
                      variant="outlined"
                      className="w-full"
                      error={errors.link ? true : false}
                      helperText={t(handleErrorMessage(port, "link"))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="link"
                  render={({ message }) => (
                    <p className="mt-1 text-sm text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>
            <div className="md:flex items-center justify-between">
              <div className="py-2 md:py-0 md:w-10/12 flex flex-col mb-5 mx-2">
                <Controller
                  name="description"
                  id="description"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("portfolio.error.description_required"),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="description"
                      name="description"
                      multiline
                      label={t("portfolio.description")}
                      variant="outlined"
                      className="w-full"
                      error={errors.description ? true : false}
                      helperText={t(handleErrorMessage(port, "description"))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => (
                    <p className="mt-1 text-sm text-red-500">{message}</p>
                  )}
                />
              </div>
              <div className="md:w-2/12 flex justify-center mx-2">
                <Button
                  status={port.button}
                  text={t("portfolio.addbtn")}
                  icon="fa fa-check"
                />
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}

const initialState = {
	button:false,
	loaded: false,
    data : [],
    description : [],
	modal:[],
	comment:[],
	error: false,
	error_message: [],
	redirect:false,	
	modal_loaded:0,
	blockbutton:false
}

export default function jobReducers(state = initialState, action) {
	switch(action.type) {
		case 'JOBS_RECORDS': {
			return {
				...state,
                data:action.payload,
				loaded: true
			}
		}
		case 'JOBS_MODAL': {			
			return {
				...state,
                modal:action.payload,
				loaded: true,
				modal_loaded:1
			}
		}		
		case 'JOBS_COMMENTS': {			
			return {
				...state,
                comment:action.payload,
				//modal: state.modal.push(action.payload),
				//modal: [...state.modal, action.payload],
				loaded: false,
				modal_loaded:0,
				blockbutton:false
			}
		}
		case 'JOBS_APPLY': {
			return {
				...state,
                apply:action.payload,
			}
		}
		case 'JOBS_APPLY_ADMIN': {
			return {
				...state,
                apply:action.payload,
			}
		}		
		case 'JOBS_ADITIONAL': {
			return {
				...state,
                description:action.payload,
			}
		}		
		case 'JOBS_ERROR': {
			return {
				...state,
				error: false,
				error_message: [],
			}
		}
		case 'JOBS_OFF': {
			return {
				...state,
				error: false,
				error_message: [],
				button: false
			}
		}
		case 'RESTART_MODAL_JOBS': {
			return {
				...state,
				modal_loaded:0
			}
		}
		case 'BLOCK_COMENT_BUTTOM': {
			return {
				...state,
				blockbutton:true
			}
		}
		case 'JOB_SEARCH_RELOAD': {
			return {
				...state,
				loaded: false
			}
		}
		default: return state;
	}
}

import React, { useEffect, useState, useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

//actions
import {
  getJobs,
  restartVacant,
} from "../../../redux/actions/admin/jobActions";

// components;
import VacantEmail from "./VacantEmail";
import { Pagination, InputSearch, TableGrid, Success } from "../../common";
import { getPermissionByModuleId } from "../../../redux/actions/admin/roleActions";
import { getPersonalInfo } from "../../../redux/actions/personalActions";

export default function VacantMain() {
  const { t, i18n } = useTranslation("common");
  let { url } = useRouteMatch();

  const columns = useMemo(
    () => [
      {
        Header: () => t("vacancies.jobName"),
        accessor: "name_job",
      },
      {
        Header: () => t("clients.clientAsoc"),
        accessor: "name_client",
      },
      {
        Header: () => t("vacancies.priority"),
        accessor: "priority_job",
        Cell: ({ value: priority_job }) => (
          <span
            className={
              priority_job === "Urgent"
                ? "inline-block px-2 py-1 font-semibold leading-tight text-xs text-red-700 bg-red-100 rounded-full"
                : "inline-block px-2 py-1 font-semibold leading-tight text-xs text-gray-500 bg-gray-100 rounded-full"
            }
          >
            {t("vacancies." + priority_job.toLowerCase())}
          </span>
        ),
      },
      {
        Header: () => t("vacancies.typeJob"),
        accessor: "type_job",
        Cell: ({ value: type_job }) => (
          <span
            className={
              type_job === "FullTime"
                ? "inline-block px-1 py-1 font-semibold text-xs text-center text-blue-700 bg-blue-100 lg:rounded-full rounded-md dark:text-red-100"
                : "inline-block px-2 py-1 font-semibold text-xs text-center text-yellow-700 content-center bg-yellow-100 lg:rounded-full rounded-md dark:bg-green-700 dark:text-red-100"
            }
          >
            {t("vacancies." + type_job)}
          </span>
        ),
      },
      {
        Header: () => t("vacancies.publicate"),
        accessor: "created_at",
        Cell: ({ value: created_at }) => {
          const localeFormat = i18n.language === "en" ? "en-US" : "es-CO";
          return new Date(created_at).toLocaleDateString(localeFormat);
        },
      },
      {
        Header: () => t("vacancies.cantVacant"),
        accessor: "cant_vacant",
      },

      {
        Header: () => t("vacancies.cantkills"),
        accessor: "skills_id_job",
        Cell: ({ value: skills_id_job }) => {
          return skills_id_job ? skills_id_job.split(",").length : 0;
        },
      },
      {
        Header: (cell) => t("vacancies.apply"),
        accessor: "num_applied",
        Cell: (cell) =>
          cell.row.original.num_applied > 0 ? (
            <div className="inline-block px-4 py-2 font-semibold leading-tight border-2 border-green-500 text-green-600 rounded-lg hover:bg-green-500 hover:text-white">
              <Link
                to={{
                  pathname: `/dropDrag/${cell.row.original.id}`,
                  state: cell.row.original,
                }}
              >
                {cell.row.original.num_applied}{" "}
                {cell.row.original.num_applied > 1
                  ? t("vacancies.candidates")
                  : t("vacancies.candidate")}
              </Link>
            </div>
          ) : (
            <div className="inline-block px-4 py-2 font-semibold leading-tight border-2 border-gray-500 text-gray-600 rounded-lg">
              {cell.row.original.num_applied}
            </div>
          ),
      },
      {
        Header: () => t("skills.actions_test"),
        accessor: "uuid",
        Cell: ({ value: uuid }) => (
          <div className="flex justify-start text-sm">
            <button className="my-1.5 border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white font-semibold  mx-1 py-2 px-2 rounded-lg">
              <Link to={`${url}/edit/${uuid}`}>
                <svg
                  className="h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  ></path>
                </svg>
              </Link>
            </button>
            <button className="my-1.5 border-2 border-red-500  hover:bg-red-500 text-red-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg">
              <Link to={`${url}/delete/${uuid}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </Link>
            </button>
            <button
              onClick={() => handleModal(uuid)}
              className="my-1.5 border-2 border-blue-500  hover:bg-blue-500 text-blue-500 hover:text-white font-bold mx-1 py-2 px-2 rounded-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </button>
          </div>
        ),
      },
    ],
    [t, i18n.language, url]
  );

  const personal = useSelector((state) => state.personalReducers);
  const role = useSelector((state) => state.roleReducers);

  const {
    data: jobs,
    loaded,
    success,
    success_message,
  } = useSelector((state) => state.jobsReducers);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: jobs,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const [value, setValue] = useState(globalFilter);

  const onChangeInputSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const dispatch = useDispatch();

  const [uuidCapt, setUuidCapt] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const handleModal = (uuid) => {
    setUuidCapt(uuid);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setUuidCapt(undefined);
    dispatch({ type: "EMAIL_OFF" });
  };
  useEffect(() => {
    dispatch(restartVacant());
  }, [dispatch]);

  useEffect(() => {
    if (!loaded) {
      dispatch(getJobs(i18n.language));
    }
  }, [dispatch, loaded, i18n.language]);

  useEffect(() => {
    if (!personal.loaded) {
      dispatch(getPersonalInfo());
    } else if (!role.permission) {
      dispatch(
        getPermissionByModuleId({
          user_id: personal.records[0].id,
          module_id: 8,
        })
      );
    }
  }, [dispatch, personal.loaded, personal.records, role.permission]);

  useEffect(() => {
    dispatch({
      type: "JOBS_RELOAD",
    });
  }, [dispatch, i18n.language]);

  return (
    <>
      <div className="mt-5">
        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-20 pb-4 mx-auto flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-2 bg-blue-50 rounded px-2 py-2 w-52">
                <i className="fas fa-briefcase mr-4"></i>
                {t("vacancies.listjobs")}
              </h2>
              <h1 className="text-xl lg:text-2xl font-semibold title-font text-gray-900">
                {t("vacancies.listvacancies")}
              </h1>
            </div>
            <div className="flex flex-col md:flex-row md:ml-auto md:mr-0 mx-auto items-start flex-shrink-0 space-x-4">
              <Link to={`${url}/add-jobs`} className="mr-2 font-poppins">
                <button
                  className="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-blue-300 focus:outline-none"
                  variant="outlined"
                >
                  <i className="fa fa-plus mr-2"></i>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs text-gray-600 mb-1">
                      {t("question.add")}
                    </span>
                    <span className="title-font font-medium">
                      {t("vacancies.newVacancies")}
                    </span>
                  </span>
                </button>
              </Link>
              {role.permission && role.permission[0]?.visible && (
                <Link to={`${url}/notifications`} className="mr-2 font-poppins">
                  <button
                    className="bg-blue-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-blue-300 focus:outline-none"
                    variant="outlined"
                  >
                    <i className="fa fa-plus mr-2"></i>
                    <span className="ml-4 flex items-start flex-col leading-none">
                      <span className="text-xs text-gray-600 mb-1">
                        {t("notification.view")}
                      </span>
                      <span className="title-font font-medium">
                        {t("notification.label")}
                      </span>
                    </span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </section>
      </div>
      <Success
        status={success}
        styleParent="mt-5"
        message={success_message}
        closer="JOBS_SUCCESS_OFF"
      />
      {uuidCapt && (
        <VacantEmail
          status={showModal}
          uuid={uuidCapt}
          closeModal={closeModal}
        />
      )}
      <InputSearch
        setValue={setValue}
        onChange={onChangeInputSearch}
        value={value}
      />
      <TableGrid
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loaded={loaded}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <Pagination
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

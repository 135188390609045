import api from "../../../utils/api";

export const getUUIDQuestions = (skill_uuid, lang) => async (dispatch) => {
   try {
      const response = await api.get(`/user-test/get/questions/${skill_uuid}/${lang}`);
      dispatch({
         type: "UT_RECORDS",
         payload: response.data,
      });
   } catch (e) {
      console.log(e);
   }
};

export const getUUIDCount = (skill_uuid, lang) => async (dispatch) => {
   try {
      const response = await api.get(`/questionscount/${skill_uuid}/${lang}`);
      dispatch({
         type: "UT_RECORDS2",
         payload: response.data,
      });
   } catch (e) {
      console.log(e);
   }
};

export const getAnswers = (question_skill_uuid, lang) => async (dispatch) => {
   try {
      const response = await api.get(
         `/user-test/get/answers/${question_skill_uuid.uuid}/${lang}`
      );       
      dispatch({
         type: "UT_SET_ANSWERS",
         payload: response.data,
      });
   } catch (e) {
      console.log(e);
   }
};

export const saveAnswer = (data) => async (dispatch) => {
   dispatch({ type: "UT_LOADED_ANSWERS_OFF" });
   try {
      const response = await api.post("/user-test", data);
      if (response.status === 200) {
         dispatch({
            type: "UT_NEXT_QUESTION",
         });
      }
   } catch (e) {
      dispatch({
         type: "UT_ERROR",
      });
   }
};

export const testCompleted = (data, user_skill_uuid) => async (dispatch) => {
   dispatch({ type: "UT_BUTTON" });
   try {
      const response = await api.post("/user-test", data);
      if (response.status === 200) {
         const response_1 = await api.put(`/user-skills/${user_skill_uuid}`);
         if (response_1.status === 200) {
            dispatch({
               type: "UT_NEXT_QUESTION",
            });
            dispatch({ type: "SKILL_LOADED_OFF" });
         }
      }
   } catch (e) {
      dispatch({
         type: "UT_ERROR",
      });
   }
};

import React from "react";
import MenuAdmin from "../../admin/Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../../img/logo-mobile.png";
import { NavLink, useLocation, Link } from "react-router-dom";
import { logoutAction } from "../../../redux/actions/loginActions";
import { useTranslation } from "react-i18next";
import Lang from "./Lang";

const StyleMenu = (props) => {
  let { url, icon, label, clickEvent, pexact } = props;
  let evento = clickEvent || null;
  const { t } = useTranslation("common");

  return (
    <NavLink exact={pexact} to={url}>
      <button
        color="inherit"
        className="font-poppins mx-6 lg:visible invisible pb-8  pt-2 text-sm font-normal lg:mt-0 text-white hover:text-blue-800"
        onClick={evento}
      >
        <i className={`${icon} mr-1`}></i> {t(label)}
      </button>
    </NavLink>
  );
};

export default function Menu(props) {
  const { logged, items, dispatch, drawer } = props;
  const { t } = useTranslation("common");
  const location = useLocation();
  return (
    <AppBar position="static" className="text-gray-800 antialiased">
      <Toolbar className="sm:space-x-2 items-center justify-around bg-gray-900 p-6">
        <div className=" sm:inline-block flex lg:items-center flex-shrink-0 text-white mr-2">
          <a href="/" >
          <img
            src={Logo}
            alt=""
            className="rounded shadow-lg fill-current mr-2 lg:ml-10"
          />
          </a>
        </div>
        <div className="flex flex-row justify-between w-full md:h-12">
          <div className="flex lg:pl-18 h-16 mt-1">
            {items.map((item) => (
              <div className="hidden lg:inline-block lg:visible mt-2"  key={item.id}>
                <StyleMenu
                  url={item.url}
                  label={item.name}
                  pexact={item.exact}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center ">       
            {logged ? (
              <>
              <div className="hidden mt-7 lg:inline-block invisible lg:visible">
                  <StyleMenu url="/profile" label={t("menu.profile")} />
                </div>
                <MenuAdmin />
                <Link
                  to={location.pathname}
                  onClick={() => dispatch(logoutAction())}
                >
                  <button
                    color="inherit"
                    className="font-poppins hidden py-3 lg:inline-block invisible lg:visible md:visible px-4 mx-2 text-sm font-semibold  leading-none border rounded text-white border-white hover:border-transparent hover:bg-blue-900 mt-4 lg:mt-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:text-white login"
                    onClick={() => dispatch(logoutAction())}
                  >
                    <i className="fa fa-sign-out-alt mr-1"></i>{" "}
                    {t("menu.logout")}
                  </button>
                </Link>
              </>
            ) : (
              <div className="h-10">
                <div className="font-poppins h-full hidden lg:inline-block invisible lg:visible md:visible px-4 mx-2 text-sm font-semibold  leading-none border rounded text-white border-white hover:border-transparent hover:bg-blue-900 mt-4 lg:mt-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:text-white login">
                  <StyleMenu url="/login" label={t("menu.login")} />
                </div>
              </div>
            )}
            <Lang/>
          </div>
        </div>
        <div className="flex lg:hidden text-center">
          <i
            className="fa fa-bars fa-2x lg:invisible sm:visible items-center"
            onClick={drawer("left", true)}
          ></i>
        </div>
      </Toolbar>
    </AppBar>
  );
}
